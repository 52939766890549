@import url('./fonts.css');

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
  position: relative;
  min-height: 100%;
  background-color: #f8f9fb;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 1.8rem;
  font-family: 'Carlito', system-ui, sans-serif;
  color: #333;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none;
}

#react-aria-modal-dialog {
  width: 100%;
}

html,
body,
#app,
#app > div {
  width: 100%;
  margin: 0px;
  padding: 0;
}
