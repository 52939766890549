@import url('https://fonts.googleapis.com/css2?family=Carlito:ital,wght@0,400;0,600;0,700;1,400&display=swap');

@font-face {
  font-family: 'MrEavesXL';
  src: local('MrEavesXL'), url(../../shared/fonts/MrEavesXLModOT-Reg.ttf) format('truetype');
}

@font-face {
  font-family: 'AbrilFatface-Regular';
  src: local('Abril Fatface'), url(../../shared/fonts/AbrilFatface-Regular.ttf);
}

@font-face {
  font-family: 'AmaticSC-Bold';
  src: local('Amatic SC'), url(../../shared/fonts/AmaticSC-Bold.ttf);
}

@font-face {
  font-family: 'BerkshireSwash-Regular';
  src: local('Berkshire Swash'), url(../../shared/fonts/BerkshireSwash-Regular.ttf);
}

@font-face {
  font-family: 'Cambay-Regular';
  src: local('Cambay'), url(../../shared/fonts/Cambay-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'DelaGothicOne-Regular';
  src: local('Dela Gothic One'), url(../../shared/fonts/DelaGothicOne-Regular.ttf);
}

@font-face {
  font-family: 'FrederickatheGreat-Regular';
  src: local('Fredericka the Great'), url(../../shared/fonts/FrederickatheGreat-Regular.ttf);
}

@font-face {
  font-family: 'FugazOne-Regular';
  src: local('Fugaz One'), url(../../shared/fonts/FugazOne-Regular.ttf);
}

@font-face {
  font-family: 'Jost-Medium';
  src: local('Jost'), url(../../shared/fonts/Jost-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Jost-Regular';
  src: local('Jost'), url(../../shared/fonts/Jost-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('Lato'), url(../../shared/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Lemon-Regular';
  src: local('Lemon'), url(../../shared/fonts/Lemon-Regular.ttf);
}

@font-face {
  font-family: 'LilitaOne';
  src: local('Lilita One'), url(../../shared/fonts/LilitaOne.ttf);
}

@font-face {
  font-family: 'Lobster-Regular';
  src: local('Lobster'), url(../../shared/fonts/Lobster-Regular.ttf);
}

@font-face {
  font-family: 'LobsterTwo-Bold';
  src: local('Lobster Two'), url(../../shared/fonts/LobsterTwo-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat'), url(../../shared/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Mulish-Regular';
  src: local('Mulish'), url(../../shared/fonts/Mulish-Regular.ttf);
}

@font-face {
  font-family: 'NunitoSans7pt-Regular';
  src: local('Nunito Sans'), url(../../shared/fonts/NunitoSans7pt-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'NunitoSans7pt-SemiBold';
  src: local('Nunito Sans'), url(../../shared/fonts/NunitoSans7pt-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('Open Sans'), url(../../shared/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Pacifico-Regular';
  src: local('Pacifico'), url(../../shared/fonts/Pacifico-Regular.ttf);
}

@font-face {
  font-family: 'Philosopher-Regular';
  src: local('Philosopher'), url(../../shared/fonts/Philosopher-Regular.ttf);
}

@font-face {
  font-family: 'PlayfairDisplay-Medium';
  src: local('Playfair Display'), url(../../shared/fonts/PlayfairDisplay-Medium.ttf);
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url(../../shared/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Qwigley-Regular';
  src: local('Qwigley'), url(../../shared/fonts/Qwigley-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto'), url(../../shared/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'RockSalt-Regular';
  src: local('Rock Salt'), url(../../shared/fonts/RockSalt-Regular.ttf);
}

@font-face {
  font-family: 'Rowdies-Regular';
  src: local('Rowdies'), url(../../shared/fonts/Rowdies-Regular.ttf);
}

@font-face {
  font-family: 'RubikDoodleShadow-Regular';
  src: local('Rubik Doodle Shadow'), url(../../shared/fonts/RubikDoodleShadow-Regular.ttf);
}

@font-face {
  font-family: 'Sacramento-Regular';
  src: local('Sacramento'), url(../../shared/fonts/Sacramento-Regular.ttf);
}

@font-face {
  font-family: 'Sail-Regular';
  src: local('Sail'), url(../../shared/fonts/Sail-Regular.ttf);
}

@font-face {
  font-family: 'Shrikhand-Regular';
  src: local('Shrikhand'), url(../../shared/fonts/Shrikhand-Regular.ttf);
}

@font-face {
  font-family: 'Syne-Medium';
  src: local('Syne'), url(../../shared/fonts/Syne-Medium.ttf);
}

@font-face {
  font-family: 'Ultra-Regular';
  src: local('Ultra'), url(../../shared/fonts/Ultra-Regular.ttf);
}

@font-face {
  font-family: 'YatraOne-Regular';
  src: local('Yatra One'), url(../../shared/fonts/YatraOne-Regular.ttf);
}

@font-face {
  font-family: 'YesevaOne-Regular';
  src: local('Yeseva One'), url(../../shared/fonts/YesevaOne-Regular.ttf);
}

@font-face {
  font-family: 'Zeyada';
  src: local('Zeyada'), url(../../shared/fonts/Zeyada.ttf);
}
